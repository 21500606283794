<template>
  <div
    class="consulting-wrapper"
    v-loading="isLoading"
    :element-loading-text="$t('loading')"
    element-loading-background="var(--report-analyse-mask)"
  >
    <div class="consulting-title__wrap">
      <span class="consulting-title__text">{{$t('consulting.title')}}</span>
      <span class="consulting-title__desc">
        {{$t('consulting.remain')}}
        <span class="title-desc__count">{{configData.used}}/{{configData.count}}</span>
      </span>
    </div>
    <div
      class="consulting-form__wrap"
    >
      <el-form
        ref="formRef"
        :model="formModel"
        :rules="rules"
        label-width="150px"
        :status-icon="false"
      >
        <el-form-item :label="$tLabel('consulting.name.label')" prop="user_name">
          <el-input :placeholder="$t('consulting.name.placeholder')" suffix-icon="" :input-style="{ width: '292px' }" v-model="formModel.user_name" show-word-limit maxlength="20" />
        </el-form-item>
        <el-form-item suffix-icon="" :label="$tLabel('consulting.email.label')" prop="email">
          <el-autocomplete
            class="email"
            v-model="formModel.email"
            name="email"
            :disabled="!!email"
            :fetch-suggestions="querySearchEmail"
            :input-style="{ width: !!email ? '338px' : '292px' }"
            show-word-limit
            maxlength="30"
            :placeholder="$t('consulting.email.placeholder')"
          />
          <!-- <el-input placeholder="请输入邮箱" :input-style="{ width: '292px' }" v-model="formModel.email" show-word-limit maxlength="30" /> -->
        </el-form-item>
        <el-form-item :label="$tLabel('consulting.phone.label')" prop="phone">
          <el-input class="phone" :placeholder="$t('consulting.phone.placeholder')" @input="changePhone" suffix-icon="" :input-style="{ width: '268px' }" v-model.trim="formModel.phone">
            <template #prepend>
              <el-select disabled v-model="selectPhone" style="width: 72px">
                <el-option label="+86" value="+86" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$tLabel('consulting.org.label')" prop="organization">
          <el-input :placeholder="$t('consulting.org.placeholder')" v-model="formModel.organization" show-word-limit maxlength="50" />
        </el-form-item>
        <el-form-item :label="$tLabel('consulting.count')" prop="sample_count">
          <el-input :placeholder="$t('consulting.count_placeholder')" v-model="formModel.sample_count" show-word-limit maxlength="20" />
        </el-form-item>
        <el-form-item :label="$tLabel('consulting.purpose')" prop="type">
          <el-radio-group v-model="formModel.type">
            <el-radio label="professional">{{$t('consulting.pro')}}</el-radio>
            <el-radio label="professional_plus">{{$t('consulting.pro_plus')}}</el-radio>
            <el-radio label="enterprise">{{$t('consulting.enterprise')}}</el-radio>
            <el-radio label="private">{{$t('consulting.local')}}</el-radio>
            <el-radio label="purchase">{{$t('consulting.point_purchase')}}</el-radio>
            <el-radio label="subscription">{{$t('consulting.renewal')}}</el-radio>
          </el-radio-group>
          <!-- <el-checkbox-group v-model="formModel.type">
            <el-checkbox label="professional">咨询专业版</el-checkbox>
            <el-checkbox label="professional_plus">咨询专业增强版</el-checkbox>
            <el-checkbox label="enterprise">咨询企业版</el-checkbox>
            <el-checkbox label="private">咨询本地沙箱</el-checkbox>
            <el-checkbox label="purchase">咨询积分购买</el-checkbox>
            <el-checkbox label="subscription">咨询订阅续费</el-checkbox>
          </el-checkbox-group> -->
        </el-form-item>
        <el-form-item :label="$tLabel('consulting.needs')" prop="description">
          <el-input :placeholder="$t('consulting.needs_placeholder')" type="textarea" show-word-limit maxlength="200" v-model="formModel.description" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm(formRef)">{{$t('action.confirm')}}</el-button>
          <el-button @click="resetForm(formRef)">{{$t('action.clear')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <backTo-top></backTo-top>
  </div>
</template>

<script setup>
import { reactive, ref, watch, onMounted, computed } from 'vue'
import { postConsult, getFeedbackCount } from '@/services/help'
import { isOrgName, isPhone, isEmail } from '@/utils/validate'
import { message } from '@/hooks/useUI'
import { useState } from '@/hooks/useVuex'
import { t } from 'app/i18n'

const formRef = ref()
const isLoading = ref(false)
const selectPhone = ref('+86')
const configData = ref({
  count: 3,
  used: 3
})

const { userInfo } = useState('user', ['userInfo'])

const formModel = reactive({
  user_name: '',
  phone: '',
  email: userInfo.value.email || '',
  organization: '',
  sample_count: '',
  type: '',
  description: ''
})

const email = computed(() => {
  return userInfo.value.email
})

watch(userInfo, () => {
  formModel.email = userInfo.value.email
})

watch(() => formModel.email, (e) => {
  if (!email.value) return
  setTimeout(() => {
    const dom = document.getElementsByName('email')[0]
    const reg = /^(.{1})(.+)(?=.{1}@)/g
    const value = e?.replace(reg, va => e[0] + '*'.repeat(va.length - 1))
    dom.value = value
  }, 100)
}, { immediate: true })

const validateName = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(t('consulting.name.null')))
  } else if (!isOrgName(value)) {
    callback(new Error(t('consulting.name.invalid')))
  } else {
    callback()
  }
}

const validateEmail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(t('consulting.email.placeholder')))
  } else if (!isEmail(value)) {
    callback(new Error(t('consulting.email.invalid')))
  } else {
    if (value.length > 30) {
      callback(new Error(t('consulting.email.length_limited')))
    } else {
      callback()
    }
  }
}

const validatePhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(t('consulting.phone.placeholder')))
  } else if (!isPhone(value)) {
    callback(new Error(t('consulting.phone.invalid')))
  } else {
    callback()
  }
}

const validateOrg = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(t('consulting.org.check')))
  } else if (!isOrgName(value)) {
    callback(new Error(t('consulting.org.invalid')))
  } else {
    callback()
  }
}

const validateType = (rule, value, callback) => {
  if (!value) {
    callback(new Error(t('consulting.purpose_invalid')))
  } else {
    callback()
  }
}

const validateSampleCount = (rule, value, callback) => {
  if (value === '') {
    callback()
  } else {
    if (/^\d+$/.test(value)) {
      callback()
    } else {
      callback(new Error(t('consulting.count_invalid')))
    }
  }
}

const rules = reactive({
  user_name: [
    { required: true, trigger: 'blur', message: t('consulting.name.null') },
    { validator: validateName, trigger: 'change' }
  ],
  email: [
    { required: true, trigger: 'blur', message: t('consulting.email.placeholder') },
    { validator: validateEmail, trigger: 'change' }
  ],
  phone: [
    { required: true, trigger: 'blur', message: t('consulting.phone.placeholder') },
    { validator: validatePhone, trigger: 'change' }
  ],
  organization: [
    { required: true, trigger: 'blur', message: t('consulting.org.check') },
    { validator: validateOrg, trigger: 'change' }
  ],
  type: [
    { required: true, trigger: 'blur', message: t('consulting.purpose_invalid') },
    { validator: validateType, trigger: 'change' }
  ],
  sample_count: [
    { validator: validateSampleCount, trigger: 'change' }
  ],
  description: [
    { required: true, trigger: 'change', message: t('consulting.needs_placeholder') }
  ]
})

onMounted(() => {
  getConfig()
})

const changePhone = (str) => {
  formModel.phone = formModel.phone.replace(' ', '')
}

const getConfig = () => {
  isLoading.value = true
  getFeedbackCount().then(res => {
    configData.value.used = configData.value.count - res.buy_contact_times
    isLoading.value = false
  }).catch(() => {
    isLoading.value = false
  })
}

const querySearchEmail = (queryString, cb) => {
  console.log(queryString, queryString.indexOf('@'))
  if (queryString.indexOf('@') > 0 || !queryString) {
    return cb([])
  }
  const results = [
    {
      value: queryString + '@163.com'
    },
    {
      value: queryString + '@sina.com'
    },
    {
      value: queryString + '@qq.com'
    },
    {
      value: queryString + '@126.com'
    },
    {
      value: queryString + '@vip.sina.com'
    },
    {
      value: queryString + '@hotmail.com'
    },
    {
      value: queryString + '@gmail.com'
    },
    {
      value: queryString + '@sohu.com'
    },
    {
      value: queryString + '@yahoo.com'
    }
  ]
  cb(results)
}

const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      if (configData.value.used === 0) {
        message(t('consulting.no_remain'), 'error')
      } else {
        isLoading.value = true
        postConsult({
          ...formModel,
          sample_count: formModel.sample_count ? parseInt(formModel.sample_count) : undefined
        }).then((res) => {
          if (res.code) {
            message(res.message, 'error')
          } else {
            message(t('consulting.success'))
          }
          isLoading.value = false
          resetForm(formEl)
          getConfig()
        })
      }
    } else {
      return false
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.consulting-wrapper {
  padding: 0 24px 10px;
  min-height: calc(100% - 70px);
  .consulting-title__wrap {
    border-bottom: 1px solid var(--color-border-2);
    box-sizing: border-box;
    line-height: 50px;
    .consulting-title__text {
      font-size: 18px;
      color: var(--color-text-1);
      font-weight: 600;
    }
    .consulting-title__desc {
      font-size: 12px;
      margin-left: 21px;
      color: var(--color-text-2);
      .title-desc__count {
        color: var(--color-priamry);
      }
    }
  }
  .consulting-form__wrap {
    width: 884px;
    // height: 550px;
    box-sizing: border-box;
    padding: 24px 0 0;
    :deep(.el-form-item) {
      margin-bottom: 24px;
    }
    :deep(.el-radio-group) {
      width: 730px;
    }
    :deep(.el-radio) {
      width: 33.333333%;
      margin-right: 0;
    }
    :deep(.el-input__count-inner) {
      color: var(--color-text-2);
      background-color: transparent;
    }
    :deep(.el-input__count) {
      background-color: transparent;
    }
    .phone {
      :deep(.el-input-group__prepend) {
        width: 30px;
        border: none !important;
      }
    }
    :deep(.email) {
      .el-input__inner {
        background: transparent !important;
      }
    }
  }
}
</style>
